<template>
  <Head>
    <title>{{$t(referenceID + '.seo.title')}}</title>
    <meta name="description" :content="$t(referenceID + '.seo.description')"/>
    <meta name="keywords" :content="$t(referenceID + '.seo.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-0 pr-0 mt-8 mb-16">
    <!--<side-breadcrumbs class="hidden md:flex col-span-1"/>-->
    <!-- Title Splash img -->
    <div class="md:col-start-2 md:col-end-9 relative">
      <div class="w-full">
        <div v-if="!reference.splash.endsWith('.mp4')" class="w-full h-full absolute">
          <img class="w-full h-full object-cover" :src="require('@/assets/img/refs/' + reference.splash)" :alt="$t(referenceID + '.splashAlt')">
        </div>
        <video v-if="reference.splash.endsWith('.mp4')" :alt="$t(referenceID + '.splashAlt')" ref="scrollVid" autoplay loop muted class="w-full h-full absolute">
          <source :src="require('@/assets/img/refs/' + reference.splash)" type="video/mp4">
        </video>
        <img src="../assets/img/placeholder_uw.png" class="w-full" alt="placeholder">
      </div>
    </div>
    <!-- Black arrows back and forth -->
    <div class="col-span-8 lg:h-0 h-32 relative">
      <div v-if="previousReference" class="absolute left-0 text-center mt-8">
        <div class="hidden lg:block w-20 font-source uppercase text-xs p-2" v-html="$t('message.references.previous')"></div>
        <div class="flex flex-row">
          <router-link :to="'/Referenzen/' + previousReference" v-on:click="()=>{}">
            <div class="w-20 h-20 bg-black lg:mr-auto p-4 transform transition duration-400 hover:-translate-y-1 hover:shadow-xl">
              <div class="w-full h-full bg-white arrow-icon transform rotate-180"></div>
            </div>
          </router-link>
          <div class="lg:hidden w-20 font-source uppercase text-xs p-2 mb-auto mt-auto" v-html="$t('message.references.previous')"></div>
        </div>
      </div>
      <div v-if="nextReference" class="absolute right-0 text-center mt-8">
        <div class="hidden lg:block w-20 font-source uppercase text-xs p-2" v-html="$t('message.references.next')"></div>
        <div class="flex flex-row">
          <div class="lg:hidden w-20 font-source uppercase text-xs p-2 mb-auto mt-auto" v-html="$t('message.references.previous')"></div>
          <router-link :to="'/Referenzen/' + nextReference" v-on:click="()=>{}">
            <div class="w-20 h-20 bg-black ml-auto p-4 transform transition duration-400 hover:-translate-y-1 hover:shadow-xl">
              <div class="w-full h-full bg-white arrow-icon"></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- Content -->
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0 gap-8">
    <!-- Reference Number red -->
    <div class="col-start-2 col-end-3">
      <div class="font-sen font-bold text-5xl red-text-outline float-right p-4"
           v-html="(referenceIdx + 1) > 9 ?
           (referenceIdx + 1) + '' :
           '0' + (referenceIdx + 1)"></div>
    </div>
    <!-- title + introtext -->
    <div class="col-start-3 col-end-8 xl:col-end-7">
      <h3 class="font-sen text-md uppercase pt-4" v-html="$t(referenceID + '.genre')"></h3>
      <h1 class="font-sen font-bold text-3xl mb-8" v-html="$t(referenceID + '.title')"></h1>
      <p class="max-w-prosefont-source mb-16" v-html="$t(referenceID + '.t1')"></p>
      <!--<img class="w-full" :src="require('@/assets/img/refs/' + reference.img1)" :alt="$t(referenceID + '.img1Alt')" />-->
      <video v-if="reference.img1.endsWith('.mp4')" :alt="$t(referenceID + '.img1Alt')" ref="scrollVid" autoplay loop muted class="w-full">
        <source :src="require('@/assets/img/refs/' + reference.img1)" type="video/mp4">
      </video>
      <div v-else-if="reference.img1.startsWith('https://www.youtube-nocookie.com')" class="w-full relative">
        <iframe :src="reference.img1" :alt="$t(referenceID + '.img1Alt')" ref="scrollVid" class="w-full h-full absolute" />
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEklEQVR42mNk+M+AFzCOKgADALyGCQGyq8YeAAAAAElFTkSuQmCC" class="w-full"/>
      </div>
      <div v-else class="w-full">
        <img class="w-full h-full object-cover" :src="require('@/assets/img/refs/' + reference.img1)" :alt="$t(referenceID + '.img1Alt')">
      </div>
    </div>
    <!-- sidebar snacks info -->
    <div class="col-start-3 xl:col-start-7 col-end-9">
      <div class="p-4 xl:ml-8 xl:mr-8 flex xl:flex-col flex-row space-x-16 xl:space-x-0">
        <div>
          <h4 class="font-sen uppercase mb-2" v-html="$t('message.references.time')"></h4>
          <p class="font-source text-sm xl:mb-8 mb-4" v-html="$t(referenceID + '.time')"></p>
        </div>
        <div>
          <h4 class="font-sen uppercase mb-2" v-html="$t('message.references.location')"></h4>
          <p class="font-source text-sm xl:mb-8 mb-4" v-html="$t(referenceID + '.location')"></p>
        </div>
        <div>
          <h4 class="font-sen uppercase mb-2" v-html="$t('message.references.partner')"></h4>
          <p class="font-source text-sm xl:mb-8 mb-4" v-html="$t(referenceID + '.partner')"></p>
        </div>
      </div>
    </div>
    <!-- content double column -->
    <div class="col-start-3 col-end-8 xl:col-end-7 md:grid md:grid-cols-2 md:gap-16 mt-8 standard mb-16">
      <div class="col-span-1 mb-8 md:mb-0" v-html="$t(referenceID + '.t2')"></div>
      <div class="col-span-1" v-html="$t(referenceID + '.t3')"></div>
    </div>
    <!-- content single column -->
    <div class="col-start-3 col-end-8 xl:col-end-7" v-html="$t(referenceID + '.t4')"></div>
    <!-- Image carousell -->
    <div class="col-start-3 col-end-8 xl:col-end-7 mb-16 relative">
      <div>
        <div class="relative">
          <div class="relative">
            <img ref="carousellEntry" class="w-full opacity-0"
                  :src="require('@/assets/img/refs/' + reference.img2)" v-on:load="loadCarousell"
                  :alt="$t(referenceID + '.img2Alt')"/>
            <div class="absolute w-full h-full top-0 overflow-hidden">
              <img v-for="(img, idx) in loadingImgs" :key="idx" :style="{
                left: (idx * 100) + '%',
                '--tw-translate-x': -(carousellIndex * 100) + '%'
              }" class="w-full h-full absolute object-cover top-0 transform transition duration-500"
                    :src="require('@/assets/img/refs/' + img.src)" v-on:load="img.onload"
                    :alt="$t(referenceID + '.img' + (idx + 2) + 'Alt')"/>
            </div>
          </div>
          <div v-if="carousellEntries > 1" class="absolute bottom-0.5 w-full z-10 flex flex-row">
            <div class="flex-grow"></div>
            <div v-for="(img, key) in loadingImgs" :class="{ 'bg-white': key === carousellIndex }" :key="key" class="flex-grow-0 w-3 h-3 cursor-pointer rounded-full border-2 border-white m-2"
                 v-on:click="carousellIndex = key"></div>
            <div class="flex-grow"></div>
          </div>
        </div>
        <div class="w-full p-8 bg-black text-white text-center font-source text-sm flex flex-row">
          <div class="flex-none arrow-icon bg-white w-12 h-12 transform rotate-180 cursor-pointer transform hover:scale-125" v-on:click="carousellIndex = (carousellEntries + carousellIndex - 1) % carousellEntries"></div>
          <div class="flex-grow pl-4 pr-4 mt-auto mb-auto" v-html="$t(referenceID + '.imgDescription' + (carousellIndex + 2))"></div>
          <div class="flex-none arrow-icon bg-white w-12 h-12 cursor-pointer transform hover:scale-125" v-on:click="carousellIndex = (carousellIndex + 1) % carousellEntries"></div>
        </div>
      </div>
    </div>
  <download-app/>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, watch } from 'vue'
import { Head } from '@egoist/vue-head'
// import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import { references, refTexts } from '@/references/ReferenceStore'
import DownloadApp from '@/components/DownloadApp.vue'
import { RouteRecordRaw, useRoute } from 'vue-router'
import router from '@/router'

export default defineComponent({
  name: 'Reference',
  i18n: refTexts,
  data () {
    return {
      loadingImgs: [],
      carousellIndex: 0,
      carousellEntries: 0
    }
  },
  setup () {
    const carousellEntry = ref(null)
    const imgsInCarousell = {}
    // TODO: dont reload page on reference swap
    /* const route = useRoute()
    watch(
      () => route.name,
      newName => {
        // loadingImgs.length = 0
        // carousellEntries = 0
        // carousellIndex = 0
      },
      {
        immediate: true
      }
    ) */
    return {
      carousellEntry,
      imgsInCarousell
    }
  },
  components: {
    Head, // ,
    // SideBreadcrumbs
    DownloadApp
  },
  computed: {
    referenceID (): string {
      return this.$route.params.refID as string
    },
    reference () {
      return references[(this.$route.params.refID) as string]
    },
    nextReference () {
      if (this.referenceIdx + 1 < Object.keys(references).length) {
        return Object.keys(references)[this.referenceIdx + 1]
      }
      return null
    },
    previousReference () {
      if (this.referenceIdx > 0) {
        return Object.keys(references)[this.referenceIdx - 1]
      }
      return null
    },
    referenceIdx (): number {
      return Object.keys(references).indexOf((this.$route.params.refID) as string)
    }
  },
  methods: {
    loadCarousell () {
      /* this.imgsInCarousell.img2 = {
        sin: () => { console.log('orig') },
        sout: () => { console.log('origout') }
      } */
      let imgidx = 2
      while (imgidx < 20) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.reference.hasOwnProperty('img' + imgidx)) {
          this.loadingImgs.push({
            src: this.reference['img' + imgidx],
            onload: () => {
              this.carousellEntries = this.carousellEntries + 1
            }
          })
        } else {
          break
        }
        // this.imgsInCarousell.push(this.carousellEntry)
        imgidx = imgidx + 1
      }
    }
  }
})
</script>

<style scoped>

</style>
