
import { ref, defineComponent, watch } from 'vue'
import { Head } from '@egoist/vue-head'
// import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import { references, refTexts } from '@/references/ReferenceStore'
import DownloadApp from '@/components/DownloadApp.vue'
import { RouteRecordRaw, useRoute } from 'vue-router'
import router from '@/router'

export default defineComponent({
  name: 'Reference',
  i18n: refTexts,
  data () {
    return {
      loadingImgs: [],
      carousellIndex: 0,
      carousellEntries: 0
    }
  },
  setup () {
    const carousellEntry = ref(null)
    const imgsInCarousell = {}
    // TODO: dont reload page on reference swap
    /* const route = useRoute()
    watch(
      () => route.name,
      newName => {
        // loadingImgs.length = 0
        // carousellEntries = 0
        // carousellIndex = 0
      },
      {
        immediate: true
      }
    ) */
    return {
      carousellEntry,
      imgsInCarousell
    }
  },
  components: {
    Head, // ,
    // SideBreadcrumbs
    DownloadApp
  },
  computed: {
    referenceID (): string {
      return this.$route.params.refID as string
    },
    reference () {
      return references[(this.$route.params.refID) as string]
    },
    nextReference () {
      if (this.referenceIdx + 1 < Object.keys(references).length) {
        return Object.keys(references)[this.referenceIdx + 1]
      }
      return null
    },
    previousReference () {
      if (this.referenceIdx > 0) {
        return Object.keys(references)[this.referenceIdx - 1]
      }
      return null
    },
    referenceIdx (): number {
      return Object.keys(references).indexOf((this.$route.params.refID) as string)
    }
  },
  methods: {
    loadCarousell () {
      /* this.imgsInCarousell.img2 = {
        sin: () => { console.log('orig') },
        sout: () => { console.log('origout') }
      } */
      let imgidx = 2
      while (imgidx < 20) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.reference.hasOwnProperty('img' + imgidx)) {
          this.loadingImgs.push({
            src: this.reference['img' + imgidx],
            onload: () => {
              this.carousellEntries = this.carousellEntries + 1
            }
          })
        } else {
          break
        }
        // this.imgsInCarousell.push(this.carousellEntry)
        imgidx = imgidx + 1
      }
    }
  }
})
